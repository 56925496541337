import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './../scss/blogs.scss';

export interface BlogsProps {}

const getDate = date => {
  const arrayDate = date.split('-');
  const day = arrayDate[0];
  const numMonth = Number(arrayDate[1]) - 1;
  const year = arrayDate[2];

  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  return (
    <p className="MiniBlog__detail-date">
      {`${day} ${months[numMonth]} ${year}`}
    </p>
  );
};

const detailBlog = (blog, index) => {
  return (
    <Link
      to={`/blog${blog.fields.slug}`}
      className="col-12 row Blog__detail-container"
      key={'blog-' + index}
    >
      <div
        className="col-4 col-md-6 Blog__detail-image_container"
        css={{ background: `url("${blog.frontmatter.image}")` }}
      ></div>
      <div className="col-8 col-md-6">
        <h2 className="Blog__detail-title">{blog.frontmatter.title}</h2>
        <h3 className="Blog__detail-subtitle">{blog.frontmatter.subtitle}</h3>
      </div>
    </Link>
  );
};

const miniBlog = (blog, index) => {
  return (
    <Link
      to={`/blog${blog.fields.slug}`}
      className="col-sm-12 col-md-6 row MiniBlog__detail-container"
      key={'blog-' + index}
    >
      <div
        className="col-12 MiniBlog__detail-image_container"
        css={{ background: `url("${blog.frontmatter.image}")` }}
      ></div>
      <div>
        <h2 className="MiniBlog__detail-title">{blog.frontmatter.title}</h2>
        {getDate(blog.frontmatter.date)}
      </div>
    </Link>
  );
};

const Blogs: React.FC<BlogsProps> = () => {
  const { allMarkdownRemark } = useStaticQuery(query);
  const [paginatorItems, setPaginatorItems] = useState<number[]>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const MAX_ITEMS_PER_PAGE = 7;

  const getPages = () => {
    if (paginatorItems.length === 0) {
      const pages = Math.ceil(
        allMarkdownRemark.nodes.length / MAX_ITEMS_PER_PAGE
      );
      const page_array = [];
      for (let index = 1; index <= pages; index++) {
        page_array.push(index);
      }
      setPaginatorItems(page_array);
    }
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const onChangePage = item => {
    setActivePage(item);
    scrollToTop();
  };

  getPages();

  return (
    <div className="screen__container">
      <Layout transparentOnTop={false}>
        <SEO title="Blogs" />
        <div>
          <section className="Blogs-container container">
            <h1 className="Blogs-header">
              Noticias del sector, consejos e información de nuestros expertos
            </h1>
            <hr className="Blogs-line" />
            <div
              className="Blogs-section row"
              css={{ '@media (max-width: 767px)': { display: 'none' } }}
            >
              {/* (En escritorio) El primer blog lo pinta con el tamaño entero de la pantalla, los demás los pinta pequeños y a los pares les pinta la línea amarilla debajo */}
              {allMarkdownRemark.nodes
                .slice(
                  (activePage - 1) * MAX_ITEMS_PER_PAGE,
                  (activePage - 1) * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE
                )
                .map((BLOG, index) => {
                  return index === 0 ? (
                    <>
                      {detailBlog(BLOG, index)} <hr className="Blogs-line" />
                    </>
                  ) : index % 2 === 0 ? (
                    <>
                      {miniBlog(BLOG, index)} <hr className="Blogs-line" />
                    </>
                  ) : (
                    miniBlog(BLOG, index)
                  );
                })}
            </div>
            <div
              className="Blogs-section row"
              css={{ '@media (min-width: 767px)': { display: 'none' } }}
            >
              {/* (En movil) Todos los blogs los pinta en la pantalla completa */}
              {allMarkdownRemark.nodes
                .slice(
                  (activePage - 1) * MAX_ITEMS_PER_PAGE,
                  (activePage - 1) * MAX_ITEMS_PER_PAGE + MAX_ITEMS_PER_PAGE
                )
                .map((BLOG, index) => detailBlog(BLOG, index))}
            </div>
            <div className="Paginator container">
              <div className="row">
                <div className="col-12 Paginator-container">
                  {paginatorItems.map((item, index) => (
                    <p
                      key={'item-' + index}
                      onClick={() => onChangePage(item)}
                      className={
                        (activePage === item && 'active') + ' Paginator_item'
                      }
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query BlogsPage {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        frontmatter {
          date(formatString: "DD-MM-YYYY")
          title
          subtitle
          image
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default Blogs;
